import {Component, OnInit} from '@angular/core';
import {HttpService} from '../services/HttpService';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToasterService} from 'angular2-toaster';
import {isObject} from 'util';
import {Router} from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [HttpService]
})
export class LoginComponent implements OnInit {

    public inputData = {
        // email: 'steve@irontree.co.za',
        // password: 'thisismylongpassword91@#',
        email: '',
        password: ''
    };

    public activeTab = 'Pending';

    constructor(
        public http: HttpService,
        public toast: ToasterService,
        public router: Router
    ) {
    }

    ngOnInit() {
    }

    doLogin() {
        if (this.inputData.email == 'steve@irontree.co.za' && this.inputData.password == 'thisismylongpassword91@#') {
            this.router.navigateByUrl('authenticated/clients');
            localStorage.setItem('user_type', 'admin');
        } else if (this.inputData.email == 'info@empirestate.co.za' && this.inputData.password == 'letmein') {
            this.router.navigateByUrl('authenticated/clients');
            localStorage.setItem('user_type', 'su');
        } else {
            this.toast.pop('error', 'Error', 'Could not log you in');
        }
    }

}
