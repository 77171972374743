import {Component, NgZone, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToasterService} from 'angular2-toaster';
import {FileUploadService} from '../services/FileUploadService';
import {HttpService} from '../services/HttpService';

@Component({
    selector: 'app-client',
    templateUrl: './client.component.html',
    styleUrls: ['./client.component.scss'],
    providers: [
        FileUploadService,
        HttpService
    ]
})
export class ClientComponent implements OnInit {

    public insertedRecords = 0;

    public showSuccessBool = false;
    public userType = '';
    public itemsToDisplay = [];
    public allUsers = [];

    public showDeleteBool = false;
    public userToDelete;

    public fileLoading = false;
    public normalLoading = false;

    public link = 'no_file';

    constructor(
        public spinner: NgxSpinnerService,
        public toast: ToasterService,
        public fileUpload: FileUploadService,
        public http: HttpService,
        public zone: NgZone
    ) {
        // this.normalLoading = true;
        // this.spinner.show();
    }

    ngOnInit() {
        this.userType = localStorage.getItem(('user_type'));
        console.log(this.userType);
        if (this.userType == 'su') {
            this.normalLoading = true;
            this.fileLoading = false;
            this.spinner.show();
            this.http.makeRequestPost(['get_all_clients', {}])
            .subscribe(
                (resp) => {
                    this.spinner.hide();
                    if (resp.data) {
                        console.log(resp.data);
                        this.allUsers = resp.data.clients.client;
                    } else {
                        this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
                    }
                },
                (error) => {
                    this.spinner.hide();
                    this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
                }
            );
        }
    }

    getClients() {
        this.userType = localStorage.getItem(('user_type'));
        console.log(this.userType);
        if (this.userType == 'su') {
            this.normalLoading = true;
            this.fileLoading = false;
            this.spinner.show();
            this.http.makeRequestPost(['get_all_clients', {}])
            .subscribe(
                (resp) => {
                    this.spinner.hide();
                    if (resp.data) {
                        console.log(resp.data);
                        this.allUsers = resp.data.clients.client;
                    } else {
                        this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
                    }
                },
                (error) => {
                    this.spinner.hide();
                    this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
                }
            );
        }
    }

    onDrop(event: any) {
        event.preventDefault();
        event.stopPropagation();
        console.log(event.dataTransfer.files);
        this.uploadFile(event.dataTransfer.files);
        // your code goes here after droping files or any
    }

    onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();

    }

    onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();

    }

    fileChange(event) {
        // console.log(event.target.files[0].type);
        this.uploadFile(event.target.files);

    }

    uploadFile(file) {
        console.log(file);
        console.log(file[0].type);
        if (file[0].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            this.normalLoading = false;
            this.fileLoading = true;
            this.spinner.show();

            this.fileUpload.uploadFile('upload_clients', file, {})
            .subscribe(
                (response) => {

                    let elem = document.getElementById('file') as HTMLInputElement;
                    elem.value = '';
                    this.spinner.hide();
                    if (response.data) {
                        for (var i = 0; i < response.data.length; i++) {
                            let record = JSON.parse(response.data[i]);
                            console.log(record);
                            if (record.result == 'success') {
                                this.insertedRecords++;
                            }
                        }
                        this.showSuccessBool = true;
                        this.getClients();
                    } else {
                        this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
                    }


                    try {
                        this.link = response.link;
                    } catch (e) {

                    }

                },
                (error) => {
                    this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
                    this.spinner.hide();
                }
            );
        } else {
            this.toast.pop('error', 'Error', 'Please upload an Excel file');
        }

    }

    onChangePage(evt) {
        this.itemsToDisplay = evt;
        console.log(evt);
    }

    showOptions(user) {
        console.log('show the options');
        user.showOptions = true;
        console.log(user);
    }

    deleteUser(user) {
        setTimeout(() => {
            for (var i = 0; i < this.itemsToDisplay.length; i++) {
                this.itemsToDisplay[i].showOptions = false;
            }
            this.userToDelete = user;
            this.showDeleteBool = true;
        });


    }

    onClickedOutside(evt) {
        for (var i = 0; i < this.itemsToDisplay.length; i++) {
            this.itemsToDisplay[i].showOptions = false;
        }
    }

    performDeleteUser() {
        this.normalLoading = true;
        this.fileLoading = false;
        this.spinner.show();
        this.http.makeRequestPost(['delete_client', {
            id: this.userToDelete.id
        }])
        .subscribe(
            (resp) => {
                this.spinner.hide();
                if (resp.data) {
                    this.toast.pop('success', 'Success', 'The user has been deleted');

                    for (var i = 0; i < this.itemsToDisplay.length; i++) {
                        if (this.itemsToDisplay[i].id == this.userToDelete.id) {
                            this.itemsToDisplay.splice(i, 1);
                        }
                    }
                    for (var i = 0; i < this.allUsers.length; i++) {
                        if (this.allUsers[i].id == this.userToDelete.id) {
                            this.allUsers.splice(i, 1);
                        }
                    }
                    this.showDeleteBool = false;
                    delete (this.userToDelete);
                } else {
                    this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
                }
            },
            (error) => {
                this.spinner.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
            }
        );
    }
}
