import {Injectable} from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';


@Injectable()
export class FileUploadService {

    constructor(
        public http2: HttpClient,
    ) {
    }

    uploadFile(path, files, params = {}) {
        let fileList: FileList = files;
        let _formData = new FormData();
        _formData.append('file', fileList[0]);
        _formData.append('params', JSON.stringify(params));
        let body = _formData;
        let headers = new HttpHeaders();
        const requestOptions = {
            headers: headers,
        };

        return Observable.create(observer => {
            return this.http2.post('https://whmcsapi.empirestate.co.za/' + path, body, requestOptions)
            .subscribe((response) => {
                observer.next(response);
                observer.complete();
            }, (error) => {
                observer.error(error);
                observer.complete();
            });


        });
    }

}
