import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToasterModule} from 'angular2-toaster';
import {LoginComponent} from './login/login.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthenticatedComponent} from './authenticated/authenticated.component';
import {JwPaginationComponent} from 'jw-angular-pagination';
import {ClickOutsideModule} from 'ng-click-outside';
import {SafePipe} from './SafePipe';
import {ColorPickerModule} from 'ngx-color-picker';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import { ClientComponent } from './client/client.component';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        AuthenticatedComponent,
        JwPaginationComponent,
        SafePipe,
        ClientComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgxSpinnerModule,
        ToasterModule.forRoot(),
        HttpClientModule,
        FormsModule,
        BrowserAnimationsModule,
        ClickOutsideModule,
        ColorPickerModule,
        NgxJsonViewerModule,
        NgbDatepickerModule

    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
