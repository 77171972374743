import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'app-authenticated',
    templateUrl: './authenticated.component.html',
    styleUrls: ['./authenticated.component.scss']
})
export class AuthenticatedComponent implements OnInit {

    public currentUrl = '';

    public userDetails = JSON.parse(localStorage.getItem('User_Details'));

    constructor(
        public router: Router
    ) {
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.currentUrl = val.url;
                console.log(this.currentUrl);
            }


        });

        this.userDetails = JSON.parse(localStorage.getItem('User_Details'));

    }

    ngOnInit() {
    }


    openDash() {
        this.router.navigateByUrl('authenticated/clients');
    }


    dologout() {
        this.router.navigateByUrl('');
    }
}
